@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;1,300&family=Poppins:wght@100;400&display=swap');


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.heading-secondary {
    font-size: 3rem;
}

.heading-primary {
    margin: 0;
    font-size: 24px;
    font-weight: 600;
}

.new-account {
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 600;
}

.new-account a {
    text-decoration: underline !important;
    margin-left: 6px;
    color: #2196f3 !important;
    cursor: pointer;
}

.span-blue {
    color: #2196f3;
}

.group-input {
    width: 100%;
}

.group-input .inp {
    width: 48%;
}

.signup-container,
.signup-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2px;
}

.signup-container {
    height: 100%;
    padding: 35px 45px 35px 45px;
}

.signup-form {
    max-width: 45rem;
    width: 100%;
}

.reset-container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2px;
    margin-top:20px;
    text-align: center;
}
.emailText{
    text-align: center;
}


.text-mute {
    color: #aaa;
}

.btn {
    padding: 10px;
    border: none;
    background: #2196f3;
    color: #fff;
    border-radius: 50px;
    cursor: pointer;
    font-family: inherit;
    font-weight: 500;
    font-size: inherit;
}

.btn-login {
    align-self: flex-end;
    width: 100%;
    margin-top: -15px;
    box-shadow: 0 5px 5px #00000020;
}

.btn-login:active {
    box-shadow: none;
}

.btn-login:hover {
    background: #2196f3 !important;
    color : white !important;
}


/* Reset Passsword */

.sendmail{
    margin-top: 20px;
}
.resetMessage{
    margin-top: -10px;
    font-size: 14pt;
    color: #dc3545;
    margin-left: 8px;
}

.btn-signUp {
    align-self: flex-end;
    width: 100%;
    margin-top: 5px;
    box-shadow: 0 5px 5px #00000020;
}
.cardText{
margin-bottom: 10px;
text-align: left;
}

.emailCard{
    margin-bottom: 20px;
    text-align: center;
    font-weight: bold;
}
    

.btn-signUp:active {
    box-shadow: none;
}

.btn-signUp:hover {
    background: #2196f3 !important;
    color : white !important;
}


.inp {
    position: relative;
}

.inp .MuiOutlinedInput-root {
    border-radius: 1rem !important;
    background: #e8f0fe !important;
}
.inp .MuiInputLabel-root{
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 1.8em !important ;
    color: rgb(59 57 57 / 60%) !important;
}

.signup-form .label {
    pointer-events: none;
    position: absolute;
    top: 1.1rem;
    left: 1.2rem;
    color: #887e7ee0;
    font-weight: 700;
    font-size: 12px;
    transition: all 0.2s;
    transform-origin: left;
    font-family: 'Poppins', sans-serif;
}

.input-icon {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 14px;
    color: #00000070;
}

.input-icon-password {
    cursor: pointer;
}

.btn-google {
    color: #222;
    background: #fff;
    border: solid 1px #eee;
    padding: 1.5rem;

    display: flex;
    justify-content: center;
    align-items: center;

    box-shadow: 0 1px 2px #00000020;
}

.btn-google img {
    width: 3rem;
    margin-right: 1rem;
}

.login-wrapper {
    max-width: 45rem;
    width: 100%;
}

.line-breaker .line {
    width: 50%;
    height: 1px;
    background: #eee;
}

.line-breaker {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ccc;

    margin: 3rem 0;
}

.line-breaker span:nth-child(2) {
    margin: 0 2rem;
}

.welcome-container {
    background: #eeeeee75;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    /* padding: 10rem; */
}

.lg {
    font-size: 6rem;
}

.welcome-container img {
    width: 100%;
}

@media screen and (max-width: 1237px) {

    .logo-wrapper img {
        width: 500px !important;
        height: 150px !important;
    }
}

@media screen and (max-width: 1080px) {

    .logo-wrapper img {
        width: 350px !important;
        height: 100px !important;
    }
}

@media screen and (max-width: 900px) {

    .logo-wrapper img {
        width: 350px !important;
        height: 100px !important;
    }
    .right-side{
        margin-right: 20px !important;
    }
}

@media screen and (max-width: 800px) {

    .logo-wrapper {
        padding: 20px 0px 20px 1px !important;
        height: 70px !important;
        justify-content: start !important;
        align-items: start !important;
    }
    .logo-wrapper img {
        width: 250px !important;
        height: 70px !important;
    }

    .app-main {
        padding: 20px !important;
    }
    .heading-primary {
        font-size: 2rem !important;
    }
    .signup-container{
        padding: 30px !important; 
    }
}

@media screen and (max-width: 600px) {

    .app-main {
        display: none !important;
    }
    .right-side {
        margin-left: 0 !important;
        flex-basis: 100% !important;
        padding : 33px !important;
    }

}
@media screen and (max-width: 700px) {

    .heading-primary {
        font-size: 1.8rem !important;
    }
    .logo-wrapper img {
        width: 240px !important;
        height: 60px !important;
    }

}
@media screen and (max-width: 750px) {

    .app-main{
        display: none !important;
    }
    .right-side {
        margin: auto !important;
        flex-basis: 450px !important
    }
    .main-logo{
        display: block !important;
    }

}


@media only screen and (max-width: 370px) {
    .new-account{
        font-size: 0.8rem !important;
    }
}

@media screen and (orientation:landscape)
and (min-device-width: 319px) 
and (max-device-width: 900px) {

    .app-container {
        height: 100% !important;
    }
}

.main-logo{
    padding-top: 12px;
    text-align: center;
    display: none;
}
.main-logo img{
    height: 50px;
    width: 180px;
}
button {
    outline: none;
    transition: 0.2s;
    cursor: pointer;
}
/* 
button:hover {
    opacity: 0.7;
} */

.app-container {
    background-color: #fbfbfb;
    width: 100%;
    height: 100vh ;
    display: flex;
    transition: 0.2s;
}

.right-side {
    margin-left: auto;
    margin-right: 65px;
    flex-basis: 530px;
    height: 100%;
    padding: 40px 33px 40px 33px;
    position: relative;
    transition: all 300ms cubic-bezier(0.19, 1, 0.56, 1);
}

.login-container {
    box-shadow: rgb(74 79 97 / 25%) 0px 13px 27px -5px, rgb(74 79 97 / 30%) 0px 8px 16px -8px;
    background-color: #fff;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    height: 100%;
}

.app-main {
    flex: 1 1;
    width: 100%;
    /* padding: 30px 30px 30px 27px; */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo-wrapper {
    /* box-shadow: rgb(74 79 97 / 25%) 0px 13px 27px -5px, rgb(74 79 97 / 30%) 0px 8px 16px -8px; */
    /* background-color: white; */
    width: 100%;
    height: 100%;
    border-radius: 12px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    display: flex;
    align-items: center;
}

.logo-wrapper img {
    width: 480px;
    height: 140px;
}

.control {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 5px;
    margin-top:-10px;
    cursor: pointer;
    font-size: 14px;
}

.control .caption {
    position: relative;
    font-weight: 600;
    top: 0.2rem;
    color: #4f5154;
}

.control input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.control__indicator {
    position: absolute;
    top: 2px;
    left: 0;
    height: 20px;
    width: 20px;
    background: #e6e6e6;
    border-radius: 4px;
}

.control--radio .control__indicator {
    border-radius: 50%;
}

.control:hover input~.control__indicator,
.control input:focus~.control__indicator {
    background: #ccc;
}

.control input:checked~.control__indicator {
    background: #007bff;
}

.control:hover input:not([disabled]):checked~.control__indicator,
.control input:checked:focus~.control__indicator {
    background: #1a88ff;
}

.control input:disabled~.control__indicator {
    background: #e6e6e6;
    opacity: 0.9;
    pointer-events: none;
}

.control__indicator:after {
    font-family: 'fontawesome';
    content: '\f00c';
    position: absolute;
    display: none;
    font-size: 16px;
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
}

.control input:checked~.control__indicator:after {
    display: block;
    color: #fff;
}

.control--checkbox .control__indicator:after {
    top: 50%;
    left: 50%;
    margin-top: -1px;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.control--checkbox input:disabled~.control__indicator:after {
    border-color: #7b7b7b;
}

.control--checkbox input:disabled:checked~.control__indicator {
    background-color: #7e0cf5;
    opacity: .2;
}

.loginInput{
    padding-top: -100px;
}

.or {
    text-align: center;
    font-weight: 500;
    border-bottom: 2px solid rgb(205 205 205);
    line-height: 0.1em;
    margin: 20px 22px; 
    margin-top: 10px;
  }
  .or span {
    background: #fff;
    padding: 0 18px;

  }
  
  .social-login {
    display: flex;
    justify-content: center;
    margin-top: -10px;
  }
  
  .social-btn {
    box-shadow: 0 4px 4px #00000020;
    background: #fff;
    border: solid 2px rgb(245 239 239);
    border-radius: 50px;
    font-weight: bold;
    display: flex;
    padding: 10px 10px;
    flex: auto;
    align-items: center;
    gap: 18px;
    justify-content: center;
    cursor: pointer;
    
  }
  .social-btn p {
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
    margin: 0;
    color: #2196f3;
  }
  .error-input{
    margin-top: -14px;
    font-size: 12px;
    color: #dc3545;
    margin-left: 8px;
  }
  .relative{
      position: relative;
      /* margin-top: -30px; */
  }
  .CircularBtn{
    left: 45%;
    top: 10%;
    position: absolute;
    width: 24px;
    height: 24px;
  }

  /* -----------------------Email Verification-------------------- */

  .card-centered {
    width: 600px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .card-centered img{
    height: 60px;
    width: 200px;
  }
  .card-centered > div{
    border-radius: 16px;
    box-shadow: rgb(74 79 97 / 25%) 0px 13px 27px -5px, rgb(74 79 97 / 25%) 0px 8px 16px -8px;
  }
  #resend-btn {
    font-size: 18px;
    letter-spacing: 0.58px;
    background: none;
    color: #429EFA;
    text-decoration: none;
    font-weight: bold;
  }
  .resend-counter{
    font-size: 14px;
    font-weight: 500;
  }
  .modal-blured .modal-content{
    border : 0  !important;
    box-shadow: rgb(74 79 97 / 25%) 0px 13px 27px -5px, rgb(74 79 97 / 25%) 0px 8px 16px -8px !important;
  }
  .modal-Logo img{
    height: 60px;
    width: 200px;
  }
  .modal-close{
    color: #3a3434;
    cursor: pointer;
    padding: 5px;
    position: absolute;
    right: 0;
    margin: 12px;
  }
  .language-selected span{
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    font-size: 12px;
    height: 37px;
    padding: 6px 10px;
    border-radius: 25px;
    cursor: pointer;
  }
  .language-selected .selected{
    color: white;
    background: #2196f3;
  }
  .language-selected h1{
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: 500;
  }