.avatar-main{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0px 13px 15px;
}
.avatar-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}
.avatar-container span{
  font-size: 13px;
  font-weight: 500;
  color: #504e4e;
}
.avatar-main .avatar-items{
    padding: 4px;
}
.avatar-main span{
 font-size: 14px;
 font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.avatar-main .avatar-category{
 display: flex;
 gap: 25px;
 margin: 0px 20px;
}
.avatar-main .avatar-gender{
  margin: 0;
  font-size: 13px;
  font-weight: 500;
}
.avatar-main .avatar-category{
 display: flex;
}
.selected-avatar {
  width: 85px;
  color: #316eed;
  background: #a8d3ff;
  border-radius: 50%;
  border: 2px solid blue;
}
.avatar-main .avatar-Head {
  display : flex;
  justify-content: space-between;
  align-items: baseline;
}
.avatar-main .MuiSvgIcon-root {
  color: #429efa !important;
}
.avatar-main .avatar-title{
  font-size: 18px;
  font-weight: 500;
}
.avatar-dialog .MuiDialogTitle-root{
  padding : 8px 24px
}
.avatar-dialog p{
  margin: 0;
  font-size: 13px;
  font-weight: 500;
}

/* ===== Scrollbar CSS ===== */
  /* Firefox */
  .avatar-dialog * {
    scrollbar-width: auto;
    scrollbar-color: #429efa #ffffff;
  }

  /* Chrome, Edge, and Safari */
  .avatar-dialog *::-webkit-scrollbar {
    width: 16px;
  }

  .avatar-dialog *::-webkit-scrollbar-track {
    background: #ffffff;
  }

  .avatar-dialog *::-webkit-scrollbar-thumb {
    background-color: #429efa;
    border-radius: 10px;
    border: 3px solid #ffffff;
  }
  .avatar-dialog .MuiButton-contained {
    text-transform: capitalize !important;
    border-radius: 25px !important;
    background-color: #429efa !important;
  }
  .MuiDialog-paper{
    border-radius: 16px !important;
  }
/* Language--------------------- */
.language-main{

    display: flex;
    padding-top: 12px;
}
.language-main .language-items{
  padding: 4px 10px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  display: flex;
  gap: 16px;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  width: 100%;
}
.language-main .language-icon{
  font-size: 13px;
  border: 1px solid black;
  border-radius: 4px;
  margin-right: 6px;
  padding: 2px;
  background: #459ff9 !important;
  color: white !important;
  border: 1px solid #459ff9 !important;

}
.language-main img{
  width: 35px;
  height: 35px;
  border-radius: 6px;
}
.language-main .language-name{
  font-size: 12px;
}
.language-main .selected{
  border: 1px solid #429efa;
}

/* ------------------------ */
.main-controls{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.main-controls .MuiButtonBase-root{
  background-color: white !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}
.main-controls .side-controls{
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.main-controls .top-right-controls{
  margin-top: 32px;
}
.main-controls .bottom-right-controls{
  margin-bottom: 22px;
}
.bottom-right-controls svg{
  height: 28px !important;
  width: 28px !important;
}

/* -------------Keyboard-------------------------- */
.keyboard-dialog .MuiDialogContent-root{
  padding: 8px 12px !important;
}
.main-keyboard{
  height: 100%;
  display: flex;
  flex-direction: column;
} 
.main-keyboard .ant-input{
  padding : 6px !important;
} 
.main-keyboard #inputBox{
  border-radius: 12px !important;  
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px !important;
} 
.main-keyboard #mainContainer{
  width: 100% !important;
  height: 45vh !important;
  margin-bottom: 12px !important;
}
.main-keyboard #listContainer{
  padding-top: 0 !important;
}
.main-keyboard #optionBtns{
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px !important;
}

/* DraggableDiv.css */
.draggable-container {
  position: absolute;
  padding: 5px;
}

.handle {
  text-align: center;
  cursor: move;
  /* background-color: #f4f4f4; */
  padding: 5px;
  /* margin-bottom: 5px; */
}

.handle img {
  background: aliceblue;
  height: 35px;
  width: 35px;
  border-radius: 27px;
}

.content {
  /* Your content styling goes here */
}
.emotion{
  display: flex;
  padding: 0px 17px 0px 3px;
  border: 1px solid #429efa;
  position: absolute;
  bottom: 60px;
  left: 12px;
  border-radius: 6px;
  justify-content: space-between;
  align-items: center;
}
.emotion img{
  width: 40px;
  height: 40px;
}
.emotion .text{
  padding-left: 12px;
  font-size: 14px;
  font-weight: 300;
}
.emotion .title{
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
}
